import PropTypes from 'prop-types';

export const configurationShape = PropTypes.shape({
  formFields: PropTypes.shape({
    revenue: PropTypes.shape({
      path: PropTypes.string,
      type: PropTypes.string,
      input: PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        min: PropTypes.number,
        numerictype: PropTypes.string,
      }),
      lineItemField: PropTypes.bool,
      tableColumn: PropTypes.bool,
      translateOptions: PropTypes.bool,
      options: PropTypes.array,
    }),
    employees: PropTypes.shape({
      path: PropTypes.string,
      type: PropTypes.string,
      input: PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        min: PropTypes.number,
        numerictype: PropTypes.string,
      }),
      lineItemField: PropTypes.bool,
      tableColumn: PropTypes.bool,
      translateOptions: PropTypes.bool,
      options: PropTypes.array,
    }),
    investmentrd: PropTypes.shape({
      path: PropTypes.string,
      type: PropTypes.string,
      input: PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        min: PropTypes.number,
        numerictype: PropTypes.string,
      }),
      lineItemField: PropTypes.bool,
      tableColumn: PropTypes.bool,
      translateOptions: PropTypes.bool,
      options: PropTypes.array,
    }),
    equityratio: PropTypes.shape({
      path: PropTypes.string,
      type: PropTypes.string,
      input: PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        min: PropTypes.number,
        numerictype: PropTypes.string,
      }),
      lineItemField: PropTypes.bool,
      tableColumn: PropTypes.bool,
      translateOptions: PropTypes.bool,
      options: PropTypes.array,
    }),
  }),
  units: PropTypes.shape({
    revenue: PropTypes.string,
    employees: PropTypes.string,
    investmentrd: PropTypes.string,
    equityratio: PropTypes.string,
  }),
  period: PropTypes.string,
  offsets: PropTypes.arrayOf(PropTypes.shape({value: PropTypes.number})),
  fieldVisibility: PropTypes.shape({
    revenue: PropTypes.string,
    employees: PropTypes.string,
    investmentrd: PropTypes.string,
    equityratio: PropTypes.string,
  }),
});

export const fieldsShape = PropTypes.shape({
  revenue: PropTypes.shape({
    path: PropTypes.string,
    type: PropTypes.string,
    input: PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      required: PropTypes.bool,
      disabled: PropTypes.bool,
      min: PropTypes.number,
      numerictype: PropTypes.string,
    }),
    lineItemField: PropTypes.bool,
    tableColumn: PropTypes.bool,
    translateOptions: PropTypes.bool,
    options: PropTypes.array,
  }),
  employees: PropTypes.shape({
    path: PropTypes.string,
    type: PropTypes.string,
    input: PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      required: PropTypes.bool,
      disabled: PropTypes.bool,
      min: PropTypes.number,
      numerictype: PropTypes.string,
    }),
    lineItemField: PropTypes.bool,
    tableColumn: PropTypes.bool,
    translateOptions: PropTypes.bool,
    options: PropTypes.array,
  }),
});

export const customerConfigShape = PropTypes.shape({
  customer: PropTypes.string,
  customerId: PropTypes.string,
  theme: PropTypes.string,
  themeError: PropTypes.bool,
  themeLoaded: PropTypes.bool,
});
