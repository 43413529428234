const TYPES = {
  STORE_FORM_DATA: 'formData/store',
};

const initState = {
  data: {
    companyContacts: [
      {
        headerTranslationKey: 'GENERAL_MANAGEMENT',
      },
      {
        headerTranslationKey: 'GENERAL_SALES',
      },
      {
        headerTranslationKey: 'GENERAL_ENGINEERING',
      },
      {
        headerTranslationKey: 'GENERAL_LOGISTICS',
      },
    ],
    categoryIds: [[]],
  },
};

export const storeFormData = (data) => ({
  type: TYPES.STORE_FORM_DATA,
  data,
});

export function reducer(state = initState, action) {
  switch (action.type) {
    case TYPES.STORE_FORM_DATA:
      return {
        data: action.data,
      };
    default:
      return state;
  }
}
