import React from 'react';
import {Switch, Route, Router} from 'react-router-dom';
import {ConnectedIntlProvider} from '@ecosio/locales';
import SsaForm from './components/SsaForm';
import FormSubmitRespond from './components/FormSubmitRespond';
import Layout from './components/Layout';

const AppLinks = [
  {
    title: 'SsaForm',
    link: '/',
  },
];

const NotFound = () => <div>404 Not Found</div>;

const AppRouter = ({history}) => (
  <ConnectedIntlProvider>
    <Router history={history}>
      <Layout>
        <Switch>
          <Route path="/" exact component={SsaForm} />
          <Route path="/horsch" exact component={SsaForm} />
          <Route path="/ecosio-supply-showcase" exact component={SsaForm} />
          <Route path="/success" component={FormSubmitRespond} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  </ConnectedIntlProvider>
);
export {AppRouter, AppLinks};
