import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {reducer as locales} from './locales';
import {reducer as formData} from './formData';
import {reducer as config} from './config';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    locales,
    formData,
    config,
  });
