import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container, Loader} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary';
import Header from '../Header';
import {loadTheme} from '../../reducers/config';

const mapStateToProps = ({locales, config}) => ({
  locale: locales.locale,
  config,
});

const mapDispatchToProps = {loadTheme};

class LayoutComponent extends Component {
  componentDidMount() {
    this.props.loadTheme();
  }

  render() {
    const {config} = this.props;
    if (!config.themeLoaded) {
      return <Loader active />;
    }

    return (
      <div>
        <ErrorBoundary>
          <Header locale={this.props.locale} />
          <Container>{this.props.children}</Container>
        </ErrorBoundary>
      </div>
    );
  }
}

// TODO: Use shape from @ecosio/components
LayoutComponent.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.any,
  config: PropTypes.any,
  loadTheme: PropTypes.any,
};

const Layout = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutComponent)
);
export default Layout;
