import React, {Fragment} from 'react';
import {FormattedMessage as Msg} from 'react-intl';
import {Segment, Message} from 'semantic-ui-react';

const FormSubmitRespond = () => {
  return (
    <Fragment>
      <Segment>
        <Message positive>
          <Message.Header>
            <Msg id="POST_SSA_MESSAGE_HEADER" />
          </Message.Header>
          <p>
            <Msg id="POST_SSA_MESSAGE" />
          </p>
        </Message>
      </Segment>
    </Fragment>
  );
};

export default FormSubmitRespond;
