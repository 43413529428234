import React from 'react';
import PropTypes from 'prop-types';
import {Message, Container, Header} from 'semantic-ui-react';

const ErrorTemplate = ({error}) => (
  <Container>
    <Message list={error.stack} error>
      <Header as="h1">Woops, something went wrong!</Header>
      <br />
      <Header as="h3">Error: {error.message}</Header>
      <pre>{error.stack}</pre>
    </Message>
  </Container>
);

ErrorTemplate.propTypes = {
  error: PropTypes.object,
};

export default ErrorTemplate;
