import {
  getSupportedLocale,
  getInitLocale,
  persistLocale,
  messages,
} from '@ecosio/locales';

export const TYPES = {
  LOCALES_SWITCH: 'locales/switch',
};

const initLocale = getInitLocale();
const initState = {
  locale: initLocale,
  messages: messages[initLocale],
};

export const switchLocale = (locale) => ({
  type: TYPES.LOCALES_SWITCH,
  locale: getSupportedLocale(locale),
});

export function reducer(state = initState, action) {
  switch (action.type) {
    case TYPES.LOCALES_SWITCH:
      persistLocale(action);
      return {
        locale: action.locale,
        messages: messages[action.locale],
      };
    default:
      return state;
  }
}
