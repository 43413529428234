import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import initServices from './helpers/initServices';

initServices();

export default ReactDOM.render(
  <App />,
  document.getElementById('root') || document.createElement('div')
);

if (module.hot) {
  module.hot.accept();
}
