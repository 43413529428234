import React from 'react';
import {Dropdown, Flag} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {localesMap} from '@ecosio/locales';
import {switchLocale} from '../../reducers/locales';

const mapStateToProps = ({locales}) => ({
  ...locales,
});

const mapDispatchToProps = (dispatch) => ({
  switchLocale: (e, data) => dispatch(switchLocale(data.value)),
});

export const LocaleSwitcherComponent = ({locale, switchLocale}) => {
  const renderFlags = localesMap.map((item) => (
    <Dropdown.Item value={item.locale} key={item.key} onClick={switchLocale}>
      <span>
        <Flag name={item.flag} />
        <FormattedMessage id={item.label} />
      </span>
    </Dropdown.Item>
  ));

  const currentFlag = [
    ...localesMap
      .filter((item) => item.locale === locale)
      .map((item) => item.flag),
  ];

  return (
    <Dropdown item pointing="top right" trigger={<Flag name={currentFlag} />}>
      <Dropdown.Menu>{renderFlags}</Dropdown.Menu>
    </Dropdown>
  );
};

LocaleSwitcherComponent.propTypes = {
  switchLocale: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocaleSwitcherComponent);
