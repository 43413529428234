import {createStore, applyMiddleware} from 'redux';
import {createBrowserHistory} from 'history';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import rootReducer from './reducers';

export const history = createBrowserHistory();

const middleWares = [applyMiddleware(thunk)];
const initState = {};

const enhancer = composeWithDevTools(...middleWares);

export default createStore(rootReducer(history), initState, enhancer);
