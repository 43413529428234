export const TYPES = {
  CONFIG_THEME_LOADED: 'config/theme_loaded',
  CONFIG_THEME_ERROR: 'config/theme_error',
};

const initState = {
  themeLoaded: false,
  theme: null,
  themeError: false,
  customer: null,
  customerId: null,
};

export const AVAILBALE_CONFIGS = {
  horsch: 'horsch-supplier-database',
  'ecosio-supply-showcase': 'ecosio-supply-showcase',
};

const STYLESHEET_KEYS = {
  horsch: 'horsch',
  'ecosio-supply-showcase': 'agrana',
};

export const loadTheme = () => (dispatch) => {
  const customer = window.location.pathname.replace('/', '');
  const stylesheets = document.getElementsByTagName('link');

  const customerId = AVAILBALE_CONFIGS[customer];
  if (!customerId) {
    return;
  }

  let style;
  let linkIdx;
  Object.keys(stylesheets).map((k, idx) => {
    if (
      stylesheets[k].rel === 'stylesheet' &&
      /semantic\.min\.css/.test(stylesheets[k].href)
    ) {
      style = stylesheets[k].href;
      linkIdx = idx;
    }
  });
  if (!style) {
    console.error(`Theme couldn't be set.`);
    return;
  }
  // Fall back to using the ecosio theme if customer is unknown
  const stylesheetKey = STYLESHEET_KEYS[customer] || 'ecosio';
  const theme = style.replace(/default/gi, stylesheetKey);
  stylesheets[linkIdx].href = theme;

  window.setTimeout(() => {
    dispatch({type: TYPES.CONFIG_THEME_LOADED, theme, customer, customerId});
  }, 500);
};

export function reducer(state = initState, action) {
  switch (action.type) {
    case TYPES.CONFIG_THEME_LOADED:
      return {
        ...state,
        themeLoaded: true,
        theme: action.theme,
        customerId: action.customerId,
        customer: action.customer,
      };
    case TYPES.CONFIG_THEME_ERROR:
      return {...state, themeLoaded: false, themeError: true};
    default:
      return state;
  }
}
