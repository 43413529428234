import React from 'react';
import {connect} from 'react-redux';
import {Menu} from 'semantic-ui-react';
import LocaleSwitcher from './LocaleSwitcher';

const mapStateToProps = ({locales}) => ({
  locale: locales.locale,
});

const AppHeaderComponent = () => (
  <Menu position="right" inverted>
    <Menu.Item>
      <div className="ui companylogo" />
    </Menu.Item>

    <Menu.Menu position="right">
      <LocaleSwitcher />
    </Menu.Menu>
  </Menu>
);

const AppHeader = connect(mapStateToProps)(AppHeaderComponent);
export default AppHeader;
